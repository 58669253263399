import * as THREE from 'three'
// import Stats from 'stats.js'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { gltfLoader, environmentMap, isAllReady } from './loader'


let desktop = window.innerWidth > 991

if (desktop) {

    /**
     * Base
     */
    // Debug
    // const gui = new dat.GUI()
    const debugObject = {}

    // Canvas
    const canvas = document.querySelectorAll('canvas.webglSmall')
    const renderers = []
    const controls = []
    const scenes = []
    const cameras = []
    const bases = []
    const canvasPositions = []
    const canvasSizes = []

    canvas.forEach((canvasSmall, index) => {

        canvasPositions.push(canvasSmall.getBoundingClientRect())

        let modelName = canvasSmall.getAttribute('data-model')
        let modelScaleCoef = canvasSmall.getAttribute('data-scale')
        // Scene
        const scene = new THREE.Scene()
        scenes.push(scene)

        /**
         * Update all materials
         */
        const updateAllMaterials = () => {
            scene.traverse((child) => {
                if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
                    child.material.envMapIntensity = debugObject.envMapIntensity
                    // child.castShadow = true
                    // child.receiveShadow = true
                }
            })
        }

        /**
         * Environment map
         */
        environmentMap.encoding = THREE.sRGBEncoding
        scene.environment = environmentMap
        debugObject.envMapIntensity = 1

        /**
         * Models
         */

        let base = new THREE.Object3D();
        scene.add(base);

        gltfLoader.load(
            `/models/${modelName}`,
            (gltf) => {
                gltf.scene.scale.set(3 * modelScaleCoef, 3 * modelScaleCoef, 3 * modelScaleCoef)
                gltf.scene.position.set(0, 0, 0)
                gltf.scene.rotation.y = Math.PI
                base.add(gltf.scene);
                updateAllMaterials()
            }
        )



        /**
         * Lights
         */
        const directionalLight = new THREE.DirectionalLight('#ffffff', 3)
        // directionalLight.castShadow = true
        // directionalLight.shadow.camera.far = 10
        // directionalLight.shadow.mapSize.set(sizes.width, sizes.height)
        // directionalLight.shadow.normalBias = 0.05
        directionalLight.position.set(0.25, 30, - 2.25)

        scene.add(directionalLight)

        /**
         * Sizes
         */

        const sizes = {
            width: canvasSmall.getAttribute('data-size'),
            height: canvasSmall.getAttribute('data-size')
        }
        canvasSizes.push(sizes)

        window.addEventListener('resize', () => {
            // Update camera
            camera.aspect = sizes.width / sizes.height
            camera.updateProjectionMatrix()

            // Update renderer
            renderer.setSize(sizes.width, sizes.height)
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        })

        /**
         * Camera
         */
        // Base camera
        const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 200)
        camera.position.set(0, 0, - 3)
        scene.add(camera)
        cameras.push(camera)

        // Controls
        const control = new OrbitControls(camera, canvasSmall)
        if (desktop) {
            control.enableDamping = true
            control.enableRotate = true
            control.enableZoom = false
            control.enablePan = false
        } else {
            control.enableDamping = true
            control.enableRotate = false
            control.enableZoom = false
            control.enablePan = false
        }
        controls.push(control)

        /**
         * Renderer
         */
        const renderer = new THREE.WebGLRenderer({
            canvas: canvasSmall,
            antialias: true,
            alpha: true
        })
        renderer.physicallyCorrectLights = true
        renderer.outputEncoding = THREE.sRGBEncoding
        renderer.toneMapping = THREE.ReinhardToneMapping
        renderer.toneMappingExposure = 2.5
        // renderer.shadowMap.enabled = true
        // renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderers.push(renderer)
        bases.push(base)


        let boutonModel
        const containerModel = canvasSmall.closest(".webglSmallContainer")
        if (containerModel) {
            boutonModel = containerModel.querySelector('.webglBouton')
            boutonModel.addEventListener('mouseenter', () => {
                base.rotation.y -= 2 * Math.PI;
            })
        }
    });


    // Stats 
    // var stats = new Stats();
    // stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.body.appendChild(stats.dom);

    // MOUSE MOVE
    let xMouse = 0;
    let yMouse = 0;
    document.addEventListener('mousemove', (event) => {
        xMouse = event.clientX - window.innerWidth / 2;  // Horizontal
        yMouse = event.clientY - window.innerHeight / 2;  // Vertical
    })

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let previousTime = 0

    const tick = () => {
        // stats.begin();

        const elapsedTime = clock.getElapsedTime()
        previousTime = elapsedTime

        canvas.forEach((canvasSmall, index) => {
            // cameras[index].updateProjectionMatrix();
            if (!isAllReady) {
                if (desktop) {
                    controls[index].update()
                    bases[index].rotation.x = lerp(bases[index].rotation.x, Math.sign(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * Math.min(Math.abs(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * 1.5 / window.innerHeight, 0.5), 0.1);
                    bases[index].rotation.y = lerp(bases[index].rotation.y, -1 * Math.sign(canvasSmall.getBoundingClientRect().left + canvasSmall.getBoundingClientRect().width / 2 - window.innerWidth / 2 - xMouse) * Math.min(Math.abs(canvasSmall.getBoundingClientRect().left + canvasSmall.getBoundingClientRect().width / 2 - window.innerWidth / 2 - xMouse) * 1.5 / window.innerWidth, 0.5), 0.1);
                } else {
                    bases[index].rotation.x = lerp(bases[index].rotation.x, Math.sign(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * Math.min(Math.abs(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * 1.5 / window.innerHeight, 0.5), 0.1);
                }
                renderers[index].render(scenes[index], cameras[index])
            } else {
                if (isElementVisible(canvasSmall)) {
                    if (desktop) {
                        controls[index].update()
                        bases[index].rotation.x = lerp(bases[index].rotation.x, Math.sign(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * Math.min(Math.abs(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * 1.5 / window.innerHeight, 0.5), 0.1);
                        bases[index].rotation.y = lerp(bases[index].rotation.y, -1 * Math.sign(canvasSmall.getBoundingClientRect().left + canvasSmall.getBoundingClientRect().width / 2 - window.innerWidth / 2 - xMouse) * Math.min(Math.abs(canvasSmall.getBoundingClientRect().left + canvasSmall.getBoundingClientRect().width / 2 - window.innerWidth / 2 - xMouse) * 1.5 / window.innerWidth, 0.5), 0.1);
                    } else {
                        bases[index].rotation.x = lerp(bases[index].rotation.x, Math.sign(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * Math.min(Math.abs(canvasSmall.getBoundingClientRect().top + canvasSmall.getBoundingClientRect().height / 2 - window.innerHeight / 2 - yMouse) * 1.5 / window.innerHeight, 0.5), 0.1);
                        bases[index].rotation.y = elapsedTime / 2
                    }
                    renderers[index].render(scenes[index], cameras[index])
                }
            }
        });

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)

        // stats.end();

    }

    tick()

}



function isElementVisible(element) {
    var rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 - rect.height &&
        rect.left >= 0 - rect.width &&
        rect.bottom <= window.innerHeight + rect.height &&
        rect.right <= window.innerWidth + rect.width
    );
}

function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end
}