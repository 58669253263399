import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'

const loadingBar = document.querySelector('#loading-bar')
const loadingBarContainer = document.querySelector('#loading-bar_container')
const loader = document.querySelector('#loader')
const barres = loader ? loader.querySelector('#barres') : undefined
let totalItemsLoaded = 0
let progressStart = false
let progressRatio = []
let isAllReady = false

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () => {
        if (loader) {
            setTimeout(() => {
                setTimeout(() => {
                    loader.classList.add('hide')
                    isAllReady = true
                }, 500);
            }, 1000 + totalItemsLoaded * 50)
        }
    },
    // Progress
    (itemsUrl, itemsLoaded, itemsTotal) => {
        if (loader) {
            barres.classList.remove('hide')
            loadingBarContainer.classList.remove('hide')
            setTimeout(() => {
                loadingBar.style.transform = `scaleX(${itemsLoaded / itemsTotal})`
                loadingBar.style.transition = 'transform 0.5s, opacity 1s'
            }, 1000)
            progressStart = true
            progressRatio.push(itemsLoaded / itemsTotal)
            totalItemsLoaded = itemsTotal
        }
    }
)

const gltfLoader = new GLTFLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

/**
 * Environment map
 */
const environmentMap = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.jpg',
    '/textures/environmentMaps/0/nx.jpg',
    '/textures/environmentMaps/0/py.jpg',
    '/textures/environmentMaps/0/ny.jpg',
    '/textures/environmentMaps/0/pz.jpg',
    '/textures/environmentMaps/0/nz.jpg'
])

export { loadingManager, gltfLoader, cubeTextureLoader, environmentMap, isAllReady }